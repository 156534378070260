*:focus {
    outline: none !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}

::-webkit-scrollbar-track {
    background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
}

.fecha {
    position: absolute;
    top: 75px;
    right: 20px;
    z-index: 1;
}

.marginTopLogo{
    margin-top: 20px
}
.blockCenter{
    display: block;
    text-align: center;
}
.btnActiveDirectory{
    font-family: "Roboto";
    min-width: 200px;
    background-color: #114477;
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    transition: .2s;
}
.btnActiveDirectory:hover{
    background-color: #113654;
    transition: .2s;
}
/* Menu */
/* .itemText div ~ div{
    padding-left: 0;
} */
.itemText > div span{
    font-size: .9rem;
}